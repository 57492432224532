import { Box, Flex, Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import CrossIcon from '@ila/components/Icons/CrossIcon';
import HamburgerIcon from '@ila/components/Icons/HamburgerIcon';
import ILALogo from '@ila/components/Icons/ILALogo';
import ILAPrograms from '@landing_pages/ila/Section/ILAPrograms';
import { useMediaQuery } from 'react-responsive';

export default function ILAHeader(props) {
  const router = useRouter();
  // const isLargeScreen = useMediaQuery({ minWidth: 993 });
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const [showPrograms, setShowPrograms] = useState(false);

  useEffect(() => {
    if (showPrograms) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showPrograms]);

  useEffect(
    () => () => {
      setShowPrograms(false);
    },
    [router.asPath]
  );

  return (
    <Box
      maxW={{
        base: '100%',
        sm: '540px',
        md: '720px',
        lg: '960px',
        xl: '1170px',
        '2xl': '1330px',
      }}
      mx="auto"
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="headerLogo"
        padding={{
          base: '20px 16px 30px 16px',
          sm: '20px 0px 30px 0px',
          xl: '40px 0 30px 0',
        }}
      >
        {/* <NextLink href="/sadhguru-academy" passHref legacyBehavior> */}
        <Link
          href="/sadhguru-academy"
          position={'relative'}
          top={{ base: '6px', md: '5px' }}
        >
          <ILALogo
            width={isSmallScreen ? '86px' : '120px'}
            height={isSmallScreen ? 'auto' : '78px'}
          />
        </Link>
        {/* </NextLink> */}
        {showPrograms ? (
          <CrossIcon
            width={isSmallScreen ? '32px' : '46px'}
            height={isSmallScreen ? '32px' : '46px'}
            onClick={() => setShowPrograms(false)}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <HamburgerIcon
            width={isSmallScreen ? '32px' : '46px'}
            height={isSmallScreen ? '32px' : '46px'}
            onClick={() => setShowPrograms(true)}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Flex>
      {showPrograms ? (
        <ILAPrograms
          setShowPrograms={setShowPrograms}
          onClick={() => setShowPrograms(false)}
        />
      ) : null}
    </Box>
  );
}
