export default function HamburgerIcon({ height, width, ...props }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="46" height="46" fill="white" />
      <rect
        x="12.9375"
        y="15.8125"
        width="20.125"
        height="2.875"
        fill="#141414"
      />
      <rect
        x="12.9375"
        y="21.5625"
        width="20.125"
        height="2.875"
        fill="#141414"
      />
      <rect
        x="12.9375"
        y="27.3125"
        width="20.125"
        height="2.875"
        fill="#141414"
      />
    </svg>
  );
}
