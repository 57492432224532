import { Box } from '@chakra-ui/react';
import Footer from '@landing_pages/ila/components/Footer/Footer';
import ILAHeader from '@landing_pages/ila/components/Header/Header';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import config from '@config';

export default function ILALayout({ children }) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', (url, { shallow }) => {
      console.log(`routing to ${url}`, `is shallow routing: ${shallow}`);
    });

    router.events.on('routeChangeError', (err, url) => {
      console.log(url, err.cancelled ? 'you cancelled the navigation' : err);
    });

    router.events.on('routeChangeComplete', url => {
      console.log(`completely routed to ${url}`);
    });

    router.events.on('beforeHistoryChange', url => {
      console.log(`appending ${url} to history`);
    });

    router.events.on('hashChangeComplete', url => {
      console.log(`navigated to ${url}`);
    });
  }, []);
  const loadInlineFormScript = () => {
    const script = document.createElement('script');
    script.src = `${config.ILAINLINEFORM}`;
    script.async = true;
    document.body.appendChild(script);
  };
  useEffect(() => {
    loadInlineFormScript();

    // Reload the script on route changes
    const handleRouteChange = () => {
      loadInlineFormScript();
    };
    // Listen to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  return (
    <Box
      bgColor="#08084A"
      backgroundImage="url('https://static.sadhguru.org/d/46272/1692985330-artboard-ila.png')"
      backgroundRepeat="no-repeat"
      bgPos="top right"
    >
      {/* header section */}
      <ILAHeader />

      {/* main content to render */}
      {children}
      {/* Footer section */}
      <Footer />
    </Box>
  );
}
