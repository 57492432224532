import { Box, Center, Image, Link, Show, Text } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
// import NextLink from 'next/link';
import { headerData } from '../../../utils/microsites/Data';

/**
 *
 * @param {Object} setShowPrograms
 * @returns
 */
const ILAPrograms = ({ setShowPrograms }) => {
  return (
    <>
      <Box
        mx="auto"
        w="100%"
        h="100vh"
        top="0"
        bottom="0"
        left={0}
        right={0}
        pb="200px"
        zIndex={9999}
        px={{ base: '16px', sm: 0 }}
      >
        <Center>
          <Text
            fontSize={['60px', '60px', '68px', '80px', '80px']}
            lineHeight={['59px', '59px', '130px', '130px', '130px']}
            textAlign="left"
            color="rgb(187, 187, 252)"
            opacity="20%"
            fontWeight="semibold"
            fontFamily="FedraSansCondensedMedium, sans-serif"
            letterSpacing="1px"
            mt={{ base: '1rem', md: '0px' }}
            marginBottom={{ base: '0rem', md: '0rem' }}
          >
            PROGRAMS OFFERED
          </Text>
        </Center>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          mx="auto"
          maxW={{ base: 'none', xl: '1020px' }}
          alignItems="center"
          justifyContent="space-between"
        >
          {headerData.data.map((data, key) => {
            return (
              <Link
                href={`${config.BASE_PATH}/${data.link}`}
                key={key}
                onClick={() => {
                  setShowPrograms(false);
                }}
                fontFamily="FedraSansStd-book"
                className="linkHover"
              >
                <Box
                  padding={{
                    base: '10px 0px 0px 10px',
                    md: '20px 0px 0px 20px',
                  }}
                >
                  <Show above="md">
                    <Image width={{ md: '83%', lg: '100%' }} src={data.img} />
                  </Show>
                  <Box width="92px" display={{ base: 'flex', md: 'none' }}>
                    <Image width={{ base: '100%' }} src={data?.imgMbl} />
                  </Box>
                </Box>
                <Box
                  color="white"
                  padding={{
                    lg: '30px 0px 0px 40px',
                    md: '30px 0px 0px 0px',
                    base: '15px 0px 0px 20px',
                  }}
                  width={{ md: '135px', lg: '185px', base: '168px' }}
                >
                  <Text fontSize="16px" fontWeight="semibold" className="link">
                    {data.heading}
                  </Text>
                  <Text fontSize="12px" color="#8686CF">
                    {data.subHeading}
                  </Text>
                </Box>
                <Box
                  width={{ base: '10%', md: '20%' }}
                  display="flex"
                  justifyContent="end"
                >
                  <LazyLoadImageComponent src="https://static.sadhguru.org/d/103645/1691490732-vector-26.svg" />
                </Box>
              </Link>
            );
          })}
        </Box>{' '}
        <Show below="md">
          <Box float="right" marginTop="80px" marginBottom="40px">
            <Image src="https://static.sadhguru.org/d/103645/1691574310-group-1429.svg" />
          </Box>
        </Show>
      </Box>
      <style jsx global>
        {`
          .linkHover:hover p.link {
            color: #f27123;
          }
          .linkHover {
            border-bottom: 1px solid #3d3d96;
            padding: 0 0 20px 0;
            display: flex;
            width: 46%;
            align-items: center;
          }
          .linkHover:last-child {
            border: none;
          }
          .linkHover:last-child,
          .linkHover:nth-child(3) {
            border: none;
          }
          .linkHover:hover {
            background: rgb(65 65 139 / 40%);
          }
          @media (max-width: 767px) {
            .linkHover {
              border-bottom: 1px solid#3d3d96;
              padding: 20px 0;
              display: flex;
              width: 100%;
              align-items: center;
            }
            .linkHover:last-child {
              border: none;
            }
            .linkHover:nth-child(3) {
              border-bottom: 1px solid#3d3d96;
            }
          }
        `}
      </style>
    </>
  );
};

export default ILAPrograms;
