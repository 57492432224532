export const headerData = {
  data: [
    {
      img: 'https://static.sadhguru.org/d/46272/1698412736-insight-logo.svg',
      heading: 'Insight',
      subHeading: 'The DNA of Success',
      link: 'sadhguru-academy/insight',
      imgMbl: 'https://static.sadhguru.org/d/46272/1698412736-insight-logo.svg'
    },
    {
      img: 'https://static.sadhguru.org/d/46272/1693555215-brand-insight-logo.svg',
      heading: 'Brand Insight',
      subHeading: 'Decoding Branding',
      link: 'sadhguru-academy/brand-insight',
      imgMbl:
        'https://static.sadhguru.org/d/103645/1691572378-clip-path-group-1.svg',
    },
    {
      img: 'https://static.sadhguru.org/d/46272/1693555217-hinar-logo.svg',
      heading: 'Human Is Not A Resource',
      subHeading: 'Unpacking Human Potential',
      link: 'sadhguru-academy/human-is-not-a-resource',
      imgMbl: 'https://static.sadhguru.org/d/103645/1691572159-a.svg',
    },
    {
      img: 'https://static.sadhguru.org/d/46272/1693555212-famex-logo.svg',
      heading: 'Family Enterprise Excellence',
      subHeading: 'Mapping the Future of your business',
      link: 'sadhguru-academy/family-enterprise-excellence',
      imgMbl:
        'https://static.sadhguru.org/d/46272/1693555212-famex-logo.svg',
    },
  ],
};