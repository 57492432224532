export default function CrossIcon({ height, width, ...props }) {
  return (
    <svg
      width={width || '46px'}
      height={height || '46px'}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="46" height="46" fill="white" />
      <path
        d="M30.1875 15.8125L15.8125 30.1875M15.8125 15.8125L30.1875 30.1875"
        stroke="#141414"
        stroke-width="2.875"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
}
