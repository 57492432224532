import { ArrowForwardIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Link,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import NextLink from 'next/link';
import { FaFacebook, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

import ILALogo from '../Icons/ILALogo';
export default function Footer() {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Container
      fontSize="16px"
      fontWeight="normal"
      line-height="29.79px"
      color="#D4D4FA"
      backgroundColor="#00002D;"
      maxWidth="100%"
      paddingRight={{ base: '0px', md: '60px' }}
      paddingLeft={{ base: '20px', md: '60px' }}
      overflow="auto"
      fontFamily="'FedraSansStd-book', sans-serif"
    >
      <Box
        display="flex"
        justifyContent={{ base: 'flex-start', md: 'space-between' }}
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
        pt={{ base: '43px', md: '76px', lg: '81px' }}
        pb={{ base: '18px', md: '23.06px', lg: '32px' }}
        pl={{ base: '0px', md: '10px' }}
        flexDir={{
          base: 'column-reverse',
          md: 'row-reverse',
          lg: 'row',
        }}
      >
        {/* <Center> */}
        <Text
          fontSize={{ base: '10px', md: '13.88px' }}
          color="#9999bb"
          lineHeight="22.025px"
          w={{ base: '191px', md: 'auto' }}
          mt={{ base: '17.8px', md: '0' }}
        >
          @Sadhguru Academy
          {/* Copyright 2023{' '}
          <Text as="p">
            Isha Leadership Academy | USA | INDIA
          </Text as="p"> */}
          <Text>All Rights Reserved</Text>
        </Text>
        {/* </Center> */}
        <NextLink href="/leadership-academy" passHref>
          <Link>
            <ILALogo
              width={isSmallScreen ? '165px' : '125px'}
              height={isSmallScreen ? '90px' : '69px'}
            />
          </Link>
        </NextLink>
      </Box>
      <Divider orientation="horizontal" />
      <Flex marginTop="40px" flexDirection={{ base: 'column', xl: 'row' }}>
        <Box>
          <UnorderedList listStyleType="none" marginLeft="0px">
            {/* <ListItem>
              <Link
                href="https://chakra-ui.com"
                isExternal
                textDecorationLine="underline"
                lineHeight="1.862rem"
              >
                Isha Leadership Blog
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="https://chakra-ui.com"
                isExternal
                textDecorationLine="underline"
                lineHeight="1.862rem"
              >
                Alumini Portal
              </Link>
            </ListItem> */}
            <ListItem mb="1.0rem">
              <Link
                href="https://isha.sadhguru.org/in/en"
                isExternal
                textDecorationLine="underline"
                lineHeight="1.862rem"
              >
                isha.sadhguru.org
              </Link>
            </ListItem>
            <ListItem mb="1.0rem">
              <NextLink
                href="/leadership-academy/privacy-policy"
                passHref
                legacyBehavior
              >
                <Link
                  marginRight="100px"
                  textDecorationLine="underline"
                  fontSize={{ base: '14px', md: '16px' }}
                  minWidth="110px"
                  lineHeight="0.866rem"
                  paddingBottom={{ base: '15px', md: '0px' }}
                >
                  Privacy Policy
                </Link>
              </NextLink>
            </ListItem>
            <ListItem mb="1.0rem">
              <NextLink
                href="/leadership-academy/terms-of-use"
                passHref
                legacyBehavior
              >
                <Link
                  marginRight="100px"
                  textDecorationLine="underline"
                  fontSize={{ base: '14px', md: '16px' }}
                  minWidth="110px"
                  lineHeight="0.866rem"
                  paddingBottom={{ base: '15px', md: '0px' }}
                >
                  Terms of Use
                </Link>
              </NextLink>
            </ListItem>
          </UnorderedList>
        </Box>
        <Spacer />
        <Box
          display="flex"
          flexDir={{ base: 'column', md: 'row' }}
          py={{ md: '40px', xl: '0px' }}
          flexGrow="1"
        >
          <Box>
            <Heading
              as="h4"
              fontSize="17.23px"
              fontFamily="FedraSansStd-medium"
              fontWeight="500"
              color="#FFFFFF"
              mb="1.0rem"
              lineHeight="1.629rem"
              mt={{ base: '1.7rem', md: '0rem' }}
            >
              Contact
            </Heading>
            <Text lineHeight="1.862rem" fontSize="16px">
              Phone: +91 83000 84888
            </Text>
            <Flex>
              <Text lineHeight="1.862rem" fontSize="16px" paddingRight="5px">
                Email:{' '}
              </Text>
              <Link
                href="mailto:leadership@ishainsight.org"
                isExternal
                color="#D4D4FA"
                fontSize="16px"
                lineHeight="1.627rem"
                _hover={{ color: '#F27123', textDecorationLine: 'underline' }}
              >
                leadership@ishainsight.org
              </Link>
            </Flex>
          </Box>
          <Spacer />
          <Box>
            <Heading
              as="h4"
              fontSize={{ base: '16.54px', md: '17.23px' }}
              fontFamily="FedraSansStd-medium"
              fontWeight="500"
              color="#FFFFFF"
              mb="1.0rem"
              lineHeight="1.629rem"
              mt={{ base: '1.7rem', md: '0rem' }}
            >
              Location
            </Heading>
            <Text lineHeight="1.862rem" fontSize="16px">
              Isha Yoga Center, Velliangiri Foothills
            </Text>
            <Text lineHeight="1.862rem" fontSize="16px">
              Ishana Vihar Post, Coimbatore
            </Text>
            <Text marginBottom="10px" lineHeight="1.862rem" fontSize="16px">
              Tamil Nadu - 641114
            </Text>
            <Link
              href="https://www.google.com/maps/place/Isha+Yoga+Center/@11.0204623,76.9634214,18.79z/data=!4m6!3m5!1s0x3ba858f8a0426c6f:0xb623304e19259c23!8m2!3d11.0207477!4d76.9641588!16s%2Fg%2F11d_ty4vvl?entry=tts&shorturl=1"
              isExternal
              textDecorationLine="underline"
              color="#F27123"
              fontSize="12px"
              lineHeight="1.627rem"
              fontFamily="'FedraSansStd-book', sans-serif"
            >
              Direction <ArrowForwardIcon w={3} h={3} color="#F27123" />
            </Link>
          </Box>
        </Box>
        <Spacer />
        <Box display="flex" flexDir="column" flexGrow="1">
          <Heading
            as="h4"
            fontSize={{ base: '16.54px', md: '17.23px' }}
            fontFamily="FedraSansStd-medium"
            fontWeight="500"
            color="#FFFFFF"
            mb={{ base: '0.7rem', md: '1.0rem' }}
            lineHeight="1.629rem"
            mt={{ base: '1.7rem', md: '0rem' }}
          >
            Follow Us
          </Heading>
          <Box display="flex" gridGap="10px">
            <Text fontSize="lg" fontWeight="500" color="#D4D4FA">
              <Link
                href="https://www.linkedin.com/company/sadhguru-academy"
                target="_blank"
              >
                <FaLinkedinIn fill="#D4D4FA" />
              </Link>
            </Text>
            <Text fontSize="lg" fontWeight="500" color="#D4D4FA">
              <Link
                href="https://twitter.com/SadhguruAcademy"
                target="_blank"
              >
                <FaTwitter fill="#D4D4FA" />
              </Link>
            </Text>
            <Text fontSize="lg" fontWeight="500" color="#D4D4FA">
              <Link
                href="https://www.facebook.com/SadhguruAcademyOfficial"
                target="_blank"
              >
                <FaFacebook fill="#D4D4FA" />
              </Link>
            </Text>

            <Text fontSize="lg" fontWeight="500" color="#D4D4FA">
              <Link
                href="https://www.instagram.com/sadhguru.academy/"
                target="_blank"
              >
                <LazyLoadImageComponent
                  src="https://static.sadhguru.org/d/46272/1693480056-ila-instagram.svg"
                  width="18px"
                  height="18px"
                  alt=""
                />
              </Link>
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex
        marginTop="80px"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="flex-end"
        marginBottom={{ base: '0', md: '80px' }}
        position="relative"
      >
        {/* <Center>
          <Text
            fontSize={{ base: '10px', md: '13.88px' }}
            color="#39396A"
            margin={{ base: '30px 0px 20px 10px', md: '0px 30px 10px 0px' }}
            lineHeight="0.866rem"
          >
            Copyright 2023 Isha Leadership Academy | USA | INDIA
          </Text>
        </Center> */}

        <Text
          fontSize={{ base: '10px', md: '13.19px' }}
          color="#FFFFFF"
          margin={{ md: '0px 30px 10px auto' }}
          position={{ base: 'absolute', md: 'unset' }}
          top={{ base: '-115px', md: 'unset' }}
          right={{ base: '20px', md: 'unset' }}
          cursor="pointer"
          _hover={{ color: '#F27123' }}
          onClick={scrollToTop}
        >
          Back to top <ArrowUpIcon color="#F27123" />
        </Text>
      </Flex>
    </Container>
  );
}
